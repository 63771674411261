import * as THREE from "three";

export default class IntroSection {
    constructor(_options) {
        // Options
        this.config = _options.config;
        this.time = _options.time;
        this.resources = _options.resources;
        this.objects = _options.objects;
        this.areas = _options.areas;
        this.walls = _options.walls;
        this.tiles = _options.tiles;
        this.debug = _options.debug;
        this.x = _options.x;
        this.y = _options.y;

        // Set up
        this.container = new THREE.Object3D();
        this.container.matrixAutoUpdate = false;
        this.container.updateMatrix();

        this.setStatic();
        this.setInstructions();
        this.setAdditionalFloorText();
        this.setOtherInstructions();
        this.setLinks();
        this.setTiles();
        this.setDikes();
    }

    setStatic() {
        this.objects.add({
            base: this.resources.items.introStaticBase.scene,
            collision: this.resources.items.introStaticCollision.scene,
            floorShadowTexture:
                this.resources.items.introStaticFloorShadowTexture,
            offset: new THREE.Vector3(0, 0, 0),
            mass: 0,
        });
    }

    setInstructions() {
        this.instructions = {};

        /**
         * Arrows
         */
        this.instructions.arrows = {};

        // Label
        this.instructions.arrows.label = {};

        this.instructions.arrows.label.texture = this.config.touch
            ? this.resources.items.introInstructionsControlsTexture
            : this.resources.items.introInstructionsArrowsTexture;
        this.instructions.arrows.label.texture.magFilter = THREE.NearestFilter;
        this.instructions.arrows.label.texture.minFilter = THREE.LinearFilter;

        this.instructions.arrows.label.material = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: this.instructions.arrows.label.texture,
            color: 0xffffff,
            depthWrite: false,
            opacity: 0,
        });

        this.instructions.arrows.label.geometry =
            this.resources.items.introInstructionsLabels.scene.children.find(
                (_mesh) => _mesh.name === "arrows"
            ).geometry;

        this.instructions.arrows.label.mesh = new THREE.Mesh(
            this.instructions.arrows.label.geometry,
            this.instructions.arrows.label.material
        );
        this.container.add(this.instructions.arrows.label.mesh);

        if (!this.config.touch) {
            // Keys
            this.instructions.arrows.up = this.objects.add({
                base: this.resources.items.introArrowKeyBase.scene,
                collision: this.resources.items.introArrowKeyCollision.scene,
                offset: new THREE.Vector3(0, 0, 0),
                rotation: new THREE.Euler(0, 0, 0),
                duplicated: true,
                shadow: { sizeX: 1, sizeY: 1, offsetZ: -0.2, alpha: 0.5 },
                mass: 1.5,
                soundName: "brick",
            });
            this.instructions.arrows.down = this.objects.add({
                base: this.resources.items.introArrowKeyBase.scene,
                collision: this.resources.items.introArrowKeyCollision.scene,
                offset: new THREE.Vector3(0, -0.8, 0),
                rotation: new THREE.Euler(0, 0, Math.PI),
                duplicated: true,
                shadow: { sizeX: 1, sizeY: 1, offsetZ: -0.2, alpha: 0.5 },
                mass: 1.5,
                soundName: "brick",
            });
            this.instructions.arrows.left = this.objects.add({
                base: this.resources.items.introArrowKeyBase.scene,
                collision: this.resources.items.introArrowKeyCollision.scene,
                offset: new THREE.Vector3(-0.8, -0.8, 0),
                rotation: new THREE.Euler(0, 0, Math.PI * 0.5),
                duplicated: true,
                shadow: { sizeX: 1, sizeY: 1, offsetZ: -0.2, alpha: 0.5 },
                mass: 1.5,
                soundName: "brick",
            });
            this.instructions.arrows.right = this.objects.add({
                base: this.resources.items.introArrowKeyBase.scene,
                collision: this.resources.items.introArrowKeyCollision.scene,
                offset: new THREE.Vector3(0.8, -0.8, 0),
                rotation: new THREE.Euler(0, 0, -Math.PI * 0.5),
                duplicated: true,
                shadow: { sizeX: 1, sizeY: 1, offsetZ: -0.2, alpha: 0.5 },
                mass: 1.5,
                soundName: "brick",
            });
        }
    }

    setOtherInstructions() {
        if (this.config.touch) {
            return;
        }

        this.otherInstructions = {};
        this.otherInstructions.x = 16;
        this.otherInstructions.y = -2;

        // Container
        this.otherInstructions.container = new THREE.Object3D();
        this.otherInstructions.container.position.x = this.otherInstructions.x;
        this.otherInstructions.container.position.y = this.otherInstructions.y;
        this.otherInstructions.container.matrixAutoUpdate = false;
        this.otherInstructions.container.updateMatrix();
        this.container.add(this.otherInstructions.container);

        // Label
        this.otherInstructions.label = {};

        this.otherInstructions.label.geometry = new THREE.PlaneBufferGeometry(
            6,
            6,
            1,
            1
        );

        this.otherInstructions.label.texture =
            this.resources.items.introInstructionsOtherTexture;
        this.otherInstructions.label.texture.magFilter = THREE.NearestFilter;
        this.otherInstructions.label.texture.minFilter = THREE.LinearFilter;

        this.otherInstructions.label.material = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: this.otherInstructions.label.texture,
            color: 0xffffff,
            depthWrite: false,
            opacity: 0,
        });

        this.otherInstructions.label.mesh = new THREE.Mesh(
            this.otherInstructions.label.geometry,
            this.otherInstructions.label.material
        );
        this.otherInstructions.label.mesh.matrixAutoUpdate = false;
        this.otherInstructions.container.add(this.otherInstructions.label.mesh);

        // Horn
        this.otherInstructions.horn = this.objects.add({
            base: this.resources.items.hornBase.scene,
            collision: this.resources.items.hornCollision.scene,
            offset: new THREE.Vector3(
                this.otherInstructions.x + 1.25,
                this.otherInstructions.y - 2.75,
                0.2
            ),
            rotation: new THREE.Euler(0, 0, 0.5),
            duplicated: true,
            shadow: { sizeX: 1.65, sizeY: 0.75, offsetZ: -0.1, alpha: 0.4 },
            mass: 1.5,
            soundName: "horn",
            sleep: false,
        });
    }

    setTiles() {
        this.tiles.add({
            start: new THREE.Vector2(0, -4.5),
            delta: new THREE.Vector2(0, -4.5),
        });
    }

    setDikes() {
        this.dikes = {};
        this.dikes.brickOptions = {
            base: this.resources.items.brickBase.scene,
            collision: this.resources.items.brickCollision.scene,
            offset: new THREE.Vector3(0, 0, 0.1),
            rotation: new THREE.Euler(0, 0, 0),
            duplicated: true,
            shadow: { sizeX: 1.2, sizeY: 1.8, offsetZ: -0.15, alpha: 0.35 },
            mass: 0.5,
            soundName: "brick",
        };

        this.walls.add({
            object: this.dikes.brickOptions,
            shape: {
                type: "brick",
                equilibrateLastLine: true,
                widthCount: 5,
                heightCount: 2,
                position: new THREE.Vector3(this.x - 12, this.y - 13, 0),
                offsetWidth: new THREE.Vector3(0, 1.05, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        this.walls.add({
            object: {
                ...this.dikes.brickOptions,
                rotation: new THREE.Euler(0, 0, Math.PI * 0.5),
            },
            shape: {
                type: "brick",
                equilibrateLastLine: true,
                widthCount: 3,
                heightCount: 2,
                position: new THREE.Vector3(this.x + 8, this.y + 6, 0),
                offsetWidth: new THREE.Vector3(1.05, 0, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        this.walls.add({
            object: this.dikes.brickOptions,
            shape: {
                type: "brick",
                equilibrateLastLine: false,
                widthCount: 3,
                heightCount: 2,
                position: new THREE.Vector3(this.x + 9.9, this.y + 4.7, 0),
                offsetWidth: new THREE.Vector3(0, -1.05, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        this.walls.add({
            object: {
                ...this.dikes.brickOptions,
                rotation: new THREE.Euler(0, 0, Math.PI * 0.5),
            },
            shape: {
                type: "brick",
                equilibrateLastLine: true,
                widthCount: 3,
                heightCount: 2,
                position: new THREE.Vector3(this.x - 14, this.y + 2, 0),
                offsetWidth: new THREE.Vector3(1.05, 0, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        this.walls.add({
            object: this.dikes.brickOptions,
            shape: {
                type: "brick",
                equilibrateLastLine: false,
                widthCount: 3,
                heightCount: 2,
                position: new THREE.Vector3(this.x - 14.8, this.y + 0.7, 0),
                offsetWidth: new THREE.Vector3(0, -1.05, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        this.walls.add({
            object: this.dikes.brickOptions,
            shape: {
                type: "brick",
                equilibrateLastLine: true,
                widthCount: 3,
                heightCount: 2,
                position: new THREE.Vector3(this.x - 14.8, this.y - 3.5, 0),
                offsetWidth: new THREE.Vector3(0, -1.05, 0),
                offsetHeight: new THREE.Vector3(0, 0, 0.45),
                randomOffset: new THREE.Vector3(0, 0, 0),
                randomRotation: new THREE.Vector3(0, 0, 0.2),
            },
        });

        if (!this.config.touch) {
            this.walls.add({
                object: {
                    ...this.dikes.brickOptions,
                    rotation: new THREE.Euler(0, 0, Math.PI * 0.5),
                },
                shape: {
                    type: "brick",
                    equilibrateLastLine: true,
                    widthCount: 2,
                    heightCount: 2,
                    position: new THREE.Vector3(this.x + 18.5, this.y + 3, 0),
                    offsetWidth: new THREE.Vector3(1.05, 0, 0),
                    offsetHeight: new THREE.Vector3(0, 0, 0.45),
                    randomOffset: new THREE.Vector3(0, 0, 0),
                    randomRotation: new THREE.Vector3(0, 0, 0.2),
                },
            });

            this.walls.add({
                object: this.dikes.brickOptions,
                shape: {
                    type: "brick",
                    equilibrateLastLine: false,
                    widthCount: 2,
                    heightCount: 2,
                    position: new THREE.Vector3(this.x + 19.9, this.y + 2.2, 0),
                    offsetWidth: new THREE.Vector3(0, -1.05, 0),
                    offsetHeight: new THREE.Vector3(0, 0, 0.45),
                    randomOffset: new THREE.Vector3(0, 0, 0),
                    randomRotation: new THREE.Vector3(0, 0, 0.2),
                },
            });
        }
    }

    setAdditionalFloorText() {
        if (this.config.touch) {
            return;
        }

        this.additionalFloorText = {};
        this.additionalFloorText.x = 0;
        this.additionalFloorText.y = -28;

        // Container
        this.additionalFloorText.container = new THREE.Object3D();
        this.additionalFloorText.container.position.x =
            this.additionalFloorText.x;
        this.additionalFloorText.container.position.y =
            this.additionalFloorText.y;
        this.additionalFloorText.container.matrixAutoUpdate = true;
        this.additionalFloorText.container.updateMatrix();
        this.container.add(this.additionalFloorText.container);

        // Label
        this.additionalFloorText.label = {};

        this.additionalFloorText.label.geometry = new THREE.PlaneBufferGeometry(
            18,
            12,
            1,
            1
        );

        this.additionalFloorText.label.texture =
            this.resources.items.additionalFloorTextTexture;
        this.additionalFloorText.label.texture.magFilter = THREE.NearestFilter;
        this.additionalFloorText.label.texture.minFilter = THREE.LinearFilter;

        this.additionalFloorText.label.material = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: this.additionalFloorText.label.texture,
            color: 0xffffff,
            depthWrite: false,
            opacity: 1,
        });

        this.additionalFloorText.label.mesh = new THREE.Mesh(
            this.additionalFloorText.label.geometry,
            this.additionalFloorText.label.material
        );

        this.additionalFloorText.label.mesh.matrixAutoUpdate = false;
        this.additionalFloorText.container.add(
            this.additionalFloorText.label.mesh
        );
    }
    // setLinksFollowus(){}

    setLinks() {
        // Set up
        this.links = {};
        this.links.x = -3.7;
        this.links.y = -36.5;
        this.links.halfExtents = {};
        this.links.halfExtents.x = 1;
        this.links.halfExtents.y = 1;
        this.links.distanceBetween = 7.4;
        this.links.labelWidth = this.links.halfExtents.x * 2 + 1;
        this.links.labelGeometry = new THREE.PlaneBufferGeometry(
            this.links.labelWidth,
            this.links.labelWidth * 0.25,
            1,
            1
        );
        this.links.labelOffset = -2.1;
        this.links.items = [];

        this.links.container = new THREE.Object3D();
        this.links.container.matrixAutoUpdate = false;
        this.container.add(this.links.container);

        // Options

        this.links.options = [
            {
                href: "https://x.com/cybertruck3011",
                labelTexture: this.resources.items.twitterSourceTexture,
            },
            {
                href: "https://t.me/cybertruck3011",
                labelTexture: this.resources.items.telegramSourceTexture,
            },
        ];

        // Create each link
        let i = 0;
        for (const _option of this.links.options) {
            // Set up
            const item = {};
            item.x = this.x + this.links.x + this.links.distanceBetween * i;
            item.y = this.y + this.links.y;
            item.href = _option.href;

            // Create area
            item.area = this.areas.add({
                position: new THREE.Vector2(item.x, item.y),
                halfExtents: new THREE.Vector2(
                    this.links.halfExtents.x,
                    this.links.halfExtents.y
                ),
            });
            item.area.on("interact", () => {
                window.open(_option.href, "_blank");
            });

            // Texture
            item.texture = _option.labelTexture;
            item.texture.magFilter = THREE.NearestFilter;
            item.texture.minFilter = THREE.LinearFilter;

            // Create label
            item.labelMesh = new THREE.Mesh(
                this.links.labelGeometry,
                new THREE.MeshBasicMaterial({
                    wireframe: false,
                    color: 0xffffff,
                    alphaMap: _option.labelTexture,
                    depthTest: true,
                    depthWrite: false,
                    transparent: true,
                })
            );
            item.labelMesh.position.x =
                item.x + this.links.labelWidth * 0.5 - this.links.halfExtents.x;
            item.labelMesh.position.y = item.y + this.links.labelOffset;
            item.labelMesh.matrixAutoUpdate = false;
            item.labelMesh.updateMatrix();
            this.links.container.add(item.labelMesh);

            // Save
            this.links.items.push(item);

            i++;
        }
    }
}
