import * as THREE from "three";

export class RaceSection {
    constructor(_options) {
        this.time = _options.time;
        this.resources = _options.resources;
        this.objects = _options.objects;
        this.areas = _options.areas;
        this.tiles = _options.tiles;
        this.debug = _options.debug;
        this.x = _options.x;
        this.y = _options.y;

        // Set up
        this.container = new THREE.Object3D();
        this.container.matrixAutoUpdate = false;

        this.setStatic();
    }

    setStatic() {
        const joined = this.resources.items.racingTrack.scene;
        // joined.rotation.z = Math.PI;
        // joined.rotateY = Math.PI / 2;

        const collision = this.resources.items.racingTrackCollision.scene;

        // console.log(collision);

        const dummy = {
            children: [
                new THREE.Mesh(
                    new THREE.PlaneGeometry(1, 1),
                    new THREE.MeshBasicMaterial()
                ),
            ],
        };

        this.objects.add({
            base: joined,
            collision: collision,
            offset: new THREE.Vector3(this.x, this.y, 0),

            scale: 1,
        });
    }
}
