import bgMusic from "../../sounds/music/bgmusicc.mp3";

const startButton = document.querySelector(".loading-button");

const loadingWindow = document.querySelector(".loading-window");

export class Interface {
    constructor(options) {
        this.progress = 0;
        this.audio = new Audio(bgMusic);
        this.audio.loop = true;
        this.init();
        this.cb = options.cb;
    }
    init() {
        startButton.addEventListener(
            "click",
            () => {
                this.audio.play();
                loadingWindow.style.display = "none";
                const handleExperienceStart = () => {
                    // this.audio.pause();
                    this.cb();
                };
                // this is needed, because raycaster is being triggered when loading continue button is clicked
                setTimeout(handleExperienceStart, 700);
            },
            { once: true }
        );
    }
    update() {
        startButton.style.display = "block";
    }
}
