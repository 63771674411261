import * as THREE from "three";

const soicalContainer = document.querySelector(".social-container");

const subtitle = document.querySelector(".subtitle");

const toolTip = document.querySelector(".tooltip");

const bubble = document.getElementById("bubble");

const text = document.getElementById("text");

const steveTexts = [
    `Let's check her out !!
(left mouse click n drag to move the camera 360­°)`,
    `Would you wanna take for a ride ?`,
];

export class Showcase {
    constructor(_options) {
        this.container = new THREE.Object3D();
        this.container.matrixAutoUpdate = false;
        this.container.updateMatrix();

        this.scene = _options.scene;
        this.camera = _options.camera;
        this.resources = _options.resources;
        this.debug = _options.debug;
        this.time = _options.time;
        this.start = _options.start;

        this.showcase;
        this.resources.on("ready", () => {
            this.init();
            this.addDebug();
            this.render();
        });

        this.clickCounter = 0;
    }

    init() {
        // .carCyberTruckChassis.scene
        this.light = new THREE.DirectionalLight("#ffffff", 2 * Math.PI);
        this.light2 = new THREE.DirectionalLight("#ffffff", 2 * Math.PI);

        this.ambientLight = new THREE.AmbientLight("#ffffff", 0.8);
        // 1, 1, 1 goof position
        // light2.position.set(0.5, 0, 0.866);
        this.light.position.set(-10, -7, 10);
        this.light2.position.set(-5, 10, 10);

        this.ambientLight.position.set(5, 1, 5);
        // NO 0-14 16 17
        this.showcase = this.resources.items.showcase.scene;
        this.girl = this.resources.items.girl.scene;
        this.girlAnimation = this.resources.items.girl.animations[0];

        this.mixer = new THREE.AnimationMixer(this.girl);
        this.mixer.clipAction(this.girlAnimation).play();

        // this.showcase.position.set(-13, -13, 3.53);
        this.showcase.position.set(0, 0, -0.1);
        this.girl.position.set(0, 0, -0.1);

        this.showcase.rotation.set(Math.PI / 2, Math.PI / 2, 0);
        this.girl.rotation.set(Math.PI / 2, Math.PI / 2, 0);

        this.showcase.name = "showcase";

        this.container.add(this.showcase);
        this.container.add(this.girl);

        this.container.add(this.light);
        this.container.add(this.light2);

        this.container.add(this.ambientLight);

        bubble.addEventListener("click", () => {
            this.clickCounter += 1;
            if (this.clickCounter === 1) {
                text.innerText = steveTexts[0];
            } else if (this.clickCounter === 2) {
                text.innerText = steveTexts[1];
            } else {
                this.start();
            }
        });
    }

    destructor() {
        this.scene.remove(this.showcase);
        this.container.remove(this.showcase);
        this.container.remove(this.girl);
        this.container.remove(this.light2);

        this.camera.orbitControls.dispose();

        soicalContainer.style.display = "none";
        // subtitle.style.display = "none";
        // toolTip.style.display = "none";
    }
    addDebug() {
        if (this.debug) {
            const helper = new THREE.DirectionalLightHelper(this.light, 5);
            this.scene.add(helper);
            this.debugFolder = this.debug.addFolder("showcase");

            this.debugFolder
                .add(this.showcase.position, "z")
                .name("position z")
                .min(-10)
                .max(10);
            this.debugFolder
                .add(this.showcase.position, "x")
                .name("position x")
                .min(-20)
                .max(-10);
            this.debugFolder
                .add(this.showcase.position, "y")
                .name("position y")
                .min(-20)
                .max(-10);

            this.debugFolder
                .add(this.light.position, "x")
                .name("light x")
                .min(-10)
                .max(10);
            this.debugFolder
                .add(this.light.position, "y")
                .name("light y")
                .min(-10)
                .max(10);
            this.debugFolder
                .add(this.light.position, "z")
                .name("light z")
                .min(-10)
                .max(10);
        }
    }

    render() {
        this.time.on("tick", () => {
            if (this.mixer) this.mixer.update(this.time.delta / 1000);
        });
    }
}
